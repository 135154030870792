import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import editIcon from "../../assets/images/icons/edit2.png";
import deleteIcon from "../../assets/images/icons/bin.png";
import {postImpDestination, getImpDestination } from "../../store/prefrences/actions"
import Map_img from "../../assets/images/map.png"
import Home_pref_img from "../../assets/images/home.png"
import { Link } from "react-router-dom"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
    OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardTitle,
  Nav,
  NavItem,
  CardI,
  CardImg
} from "reactstrap"


class impDates extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    handleValidSubmit(value){
      //console.log(value)
      this.props.postImpDestination(value, this.props.history);
  
    }
    componentDidMount() {
      const { onGetImpDestination } = this.props;
      onGetImpDestination(localStorage.getItem('userId'));
  }
    render() {
      const { impdestinations } = this.props;
        return (
            <React.Fragment>
               {/* {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null} */}
              <ToastContainer autoClose={2000} />
                <div className="page-content">
          <MetaTags>
            <title>Preferences | Realty Space</title>
          </MetaTags>
          <Container fluid>
             {/* <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Important Destinations")}
              heading={this.props.t("Set your important destination")}
            /> */}
            {/* <h2>Set your important destination</h2> */}
            <Row>
                  <Col sm="9">
              
                  <Row>
                  <Col sm="3"></Col>
      <Col lg="6">
        <Nav tabs className="projectTab pref-tab">
     
          
            <NavItem>
              <Link 
                className={`nav-link`} 
                to="/prefrences/home-prefrences"
              >
                <span className="d-none d-sm-block">My Home</span>
              </Link>
              </NavItem>
              <NavItem>
              <Link 
                className={`nav-link active`} 
                to="/prefrences/important-destinations"
              >
                <span className="d-none d-sm-block">Important Destinations</span>
              </Link>
            </NavItem>
        
        </Nav>
      </Col>
                    <Col sm="12">
                      <Card className="rs-card-bg"> 
                        <CardBody className="text-rs">
                          <h5 className="card-title text-uppercase">define your home preferences</h5>
                          <p style={{fontSize:16}}>
                          Create your ideal home profile by selecting standard features and adding personalized preferences.
                          Specify your must-haves, including:
                          </p>
                          <ul>
                            <li>Home type and size</li>
                            <li>Number of bedrooms and bathrooms</li>
                          </ul>

                          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    destinationTitle: (this.state && this.state.destinationTitle) || "",
                    destinationAddress: (this.state && this.state.destinationAddress) || "",
                    clientId: localStorage.getItem('userId'),
                    agentId: localStorage.getItem('agentId')

                  }}
                  validationSchema={Yup.object().shape({
                    destinationTitle: Yup.string().required("This is Required"),
                    destinationAddress: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={this.handleValidSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="destinationTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="destinationTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.destinationTitle && touched.destinationTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="destinationTitle"
                            />
                                <ErrorMessage
                                  name="destinationTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="destinationAddress">
                              {this.props.t("Address")}
                            </Label>
                            <Field
                              name="destinationAddress"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.destinationAddress && touched.destinationAddress
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="destinationAddress"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                       
                       <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 27}} type="submit" className="btn btn-primary w-md">Add</button></div></Col>



                       <h5 className="card-title">Important Destinations History</h5>
					 <div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								<th>Title</th>
								<th>Address</th>
								<th>Edit | Remove</th>
               
                
                {/* <th>Action</th> */}
							  </tr>
							</thead>
							<tbody>
              {/* {map(impdestinations, (impdestination, appoint) => (
                  <tr key={"_app_" + appoint}>
                     <td>{impdestination.destinationTitle}</td>
                    <td>{impdestination.destinationAddress}</td>
                   
                    
                    
                    <td>
                    <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(impdestination)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(impdestination)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                    
                    </td>
                  </tr>            
               ))} */}
               <tr>
                <td>Office</td>
                <td>1815 Ironstome Manor, Pickering, ON L1W 3X8</td>
                <td> <img className='tab-img me-2' src={editIcon}/> <img className='tab-img' src={deleteIcon} /></td>
               </tr>

               <tr>
                <td>GYM</td>
                <td>345 Kingston Road, ON L1X 0C4</td>
                <td> <img className='tab-img me-2' src={editIcon}/> <img className='tab-img' src={deleteIcon} /></td>
               </tr>

               <tr>
                <td>Army&apos;s School</td>
                <td>1532 Glen Edent Crt, Pickering, ON L1A 3W9</td> 
                <td> <img className='tab-img me-2' src={editIcon}/> <img className='tab-img' src={deleteIcon} /></td>
               </tr>
							  
							</tbody>
						  </table>

            
						</div> 
                    </Row>
                </Form>
                  )}
                </Formik>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>
                  </Col>
                    <Col sm="3">
                    <Card className="rs-card-bg">
                    <CardBody>
                      <CardImg src={Map_img} />
                    </CardBody>
                    </Card>
                    </Col>
              </Row>
               



             
           
            </Container>
            </div>
            </React.Fragment>
        )
    }
}
impDates.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    postImpDestination:PropTypes.func,
    loading:PropTypes.object,
    onGetImpDestination:PropTypes.func,
    impdestinations:PropTypes.array


}

const mapStateToProps = ({ Prefrences }) => (
    {
      impdestinations:Prefrences.impdestinations,
      loading: Prefrences.loading
    })

const mapDispatchToProps = dispatch => ({
  postImpDestination: (data) => dispatch(postImpDestination(data)),
  onGetImpDestination: (clientId) => dispatch(getImpDestination(clientId))

});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(impDates))