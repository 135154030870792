import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { isEmpty, map, size } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import {postHomePref, getHomePref, updateHomePref } from "../../store/prefrences/actions"
import prefImg from "../../assets/images/rsicons/prefrence.png"

import editIcon from "../../assets/images/icons/edit2.png";
import deleteIcon from "../../assets/images/icons/bin.png";
import DeleteModal from "../../components/Common/DeleteModal";
import Map_img from "../../assets/images/map.png"
import Home_pref_img from "../../assets/images/home.png"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse,
    Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardText,
  CardTitle,
  CardImg,
  Nav,
  NavItem
} from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";


class homePref extends Component {
    constructor(props) {
        super(props)
        this.state = {
          homeprefrence:''
        }

        this.handleHomePrefSubmit = this.handleHomePrefSubmit.bind(this);
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
      this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
      this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
      }));
    };
    onClickDelete = (homeprefrence) => {
      this.setState({ homeprefrence: homeprefrence });
      this.setState({ deleteModal: true });
    };
  
    handleDeleteHomePref = () => {
      const { onDeleteHomePref } = this.props;
      const { homeprefrence } = this.state;
    
      if (homeprefrence.id !== undefined) {
        onDeleteHomePref(homeprefrence);
        this.setState({ deleteModal: false });
      }
    };
    toggle() {
      this.setState(prevState => ({
        isRight: !prevState.isRight
      }));
    }
    handleHomePrefSubmit(value){
      //console.log(value)
      this.props.postHomePref(value, this.props.history);
  
    }
    componentDidMount() {
      const { onGetHomePref } = this.props;
      onGetHomePref(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { homeprefrences } = this.props;
    if (!isEmpty(homeprefrences) && size(prevProps.homeprefrences) !== size(homeprefrences)) {
      this.setState({ homeprefrences: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ homeprefrence: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const homeprefrence = arg;
    this.setState({
      homeprefrence: {
        id: homeprefrence.id,
        clientBuyerHomePreferenceAnswerId: homeprefrence.clientBuyerHomePreferenceAnswerId,
        homePreferenceAnswerId: homeprefrence.homePreferenceAnswerId,
        homePreferenceTitle:homeprefrence.homePreferenceTitle,
        answersOption:homeprefrence.answersOption,
        preference:homeprefrence.preference,
        clientId: homeprefrence.clientId,
        isActive:homeprefrence.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
    render() {
        const {homeprefrences } = this.props;
        const { isEdit, deleteModal } = this.state;
        const homeprefrence = this.state.homeprefrence;
        return (
            <React.Fragment>
              {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null} 
              <ToastContainer autoClose={2000} />
              <DeleteModal
              show={deleteModal}
              onDeleteClick={this.handleDeleteHomePref}
              onCloseClick={() => this.setState({ deleteModal: false })}
            />
                <div className="page-content">
          <MetaTags>
            <title>Preferences | Realty Space</title>
          </MetaTags>
          <Container fluid>
             {/* <Breadcrumbs
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t("Home Preferences")}
            /> */}
              <Row>
                  <Col sm="9">
              
                  <Row>
                  <Col sm="3"></Col>
      <Col lg="6">
        <Nav tabs className="projectTab pref-tab">
     
          
            <NavItem>
              <Link 
                className={`nav-link active`} 
                to="/prefrences/home-prefrences"
              >
                <span className="d-none d-sm-block">My Home</span>
              </Link>
              </NavItem>
              <NavItem>
              <Link 
                className={`nav-link`} 
                to="/prefrences/important-destinations"
              >
                <span className="d-none d-sm-block">Important Destinations</span>
              </Link>
            </NavItem>
        
        </Nav>
      </Col>
                    <Col sm="12">
                      <Card className="rs-card-bg"> 
                        <CardBody className="text-rs">
                          <h5 className="card-title text-uppercase">define your home preferences</h5>
                          <p style={{fontSize:16}}>
                          Create your ideal home profile by selecting standard features and adding personalized preferences.
                          Specify your must-haves, including:
                          </p>
                          <ul>
                            <li>Home type and size</li>
                            <li>Number of bedrooms and bathrooms</li>
                          </ul>

                          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    homePreferenceTitle: (this.state && this.state.homePreferenceTitle) || "",
                    answersOption: (this.state && this.state.answersOption) || "",
                    clientId: localStorage.getItem('userId')

                  }}
                  validationSchema={Yup.object().shape({
                    homePreferenceTitle: Yup.string().required("This is Required"),
                    answersOption: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={this.handleHomePrefSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                   <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="preference">
                              {this.props.t("Type of house")}
                            </Label>
                            <Field as="select" name="houseType" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="Condo">Condo</option>
                               
                            </Field>
                          </FormGroup>
                        </Col>  
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePreferenceTitle">
                              {this.props.t("Min. price")}
                            </Label>
                            <Field
                              name="homePreferenceTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.homePreferenceTitle && touched.homePreferenceTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="homePreferenceTitle"
                            />
                                <ErrorMessage
                                  name="homePreferenceTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Max price")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("Square feet")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("# Bedrooms")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col> 
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="answersOption">
                              {this.props.t("# of bathrooms")}
                            </Label>
                            <Field
                              name="answersOption"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.answersOption && touched.answersOption
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mls"
                            />
                                <ErrorMessage
                                  name="answersOption"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col> 
                       <Col sm="2">  <div className="clearfix"><button style={{ marginTop: 10}} type="submit" className="btn btn-primary w-sm">Save</button></div></Col>
                    </Row>
              
                
                </Form>
                  )}
                </Formik>

                        <h6 style={{marginTop:40, color:'#000', fontSize:16}}>Create your custom preferences </h6>

                        <ul>
                            <li>Desired amenities (pool, gym, etc.)</li>
                            <li>Proximity to public transport, schools, shopping, entertainment, and more</li>
                          </ul>

                          <button style={{ marginTop: 20}} type="button" className="btn btn-custom w-sm">Add New</button>


                          <div className="">
                    <table className="table table-striped">
                      
                      <tbody>
                                <tr>
                                    <td>Need an open concept kitchen</td>
                                  
                                    <td>
                                            <div className="d-flex gap-3">
                                            <Link className="text-purpol" to="#"><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></Link> {" "} <Link className="text-danger" to="#"><i className="mdi mdi-delete font-size-18" id="edittooltip"></i></Link>
                                            </div>
                                            </td>
                                </tr>
                                </tbody>
                    </table>


                  </div>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>
                  </Col>
                    <Col sm="3">
                    <Card className="rs-card-bg">
                    <CardBody>
                      <CardImg src={Home_pref_img} />
                    </CardBody>
                    </Card>
                    </Col>
              </Row>
              
   
            </Container>
            </div>
            </React.Fragment>
        )
    }
}
homePref.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    postHomePref:PropTypes.func,
    onGetHomePref:PropTypes.func,
    homeprefrences:PropTypes.array,
    loading:PropTypes.object,
    onDeleteHomePref:PropTypes.func,
    onUpdateHomePref:PropTypes.func


}

const mapStateToProps = ({ Prefrences }) => (
    {
      homeprefrences : Prefrences.homeprefrences,
      loading:Prefrences.loading

    })

const mapDispatchToProps = dispatch => ({
    postHomePref: (data) => dispatch(postHomePref(data)),
    onGetHomePref: (clientId) => dispatch(getHomePref(clientId))
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(homePref))